
import TextButton from "@/components/atomics/TextButton.vue";
import FlatButton from "@/components/atomics/FlatButton.vue";
import ValidationTextField from "@/components/atomics/ValidationTextField.vue";
import { defineComponent } from "vue";
import useFormHandler from "@/lib/compositional-logic/useFormHandler";
import { AuthRepository, RepositoryFactory } from "@/lib/https";
import { useForm } from "vee-validate";
import { useRouter } from "vue-router";
import { storage, key } from "@/lib/storage";

export default defineComponent({
  name: "TwoFactorLogin",
  components: {
    ValidationTextField,
    TextButton,
    FlatButton,
  },

  props: {
    email: {
      type: String,
      required: true,
    },
    password: {
      type: String,
      required: true,
    },
    afterLoginData: { type: String, default: "" },
    isContractLogin: { type: String, default: "false" },
    requireAccessCode: { type: String, default: "false" },
  },

  setup(props) {
    const router = useRouter();

    const { loginByEmail } =
      RepositoryFactory.getRepository<AuthRepository>(AuthRepository);

    const form = useForm({
      initialValues: {
        authenticationCode: "",
        email: props.email,
        password: props.password,
      },
    });

    const { formHandle } = useFormHandler(form, loginByEmail);
    const verifyCode = async function () {
      const response = await formHandle().catch((e) => {
        form.setErrors({
          authenticationCode: e.data.message,
        });
      });

      if (response) {
        const localStorage = storage.getLocalStorage();
        localStorage.add(key.sessionId, response.sessionId);

        if (props.isContractLogin == "true") {
          const localStorage = storage.getLocalStorage();
          const guestCode = localStorage.get("guestCode");
          const decode = Buffer.from(guestCode, "base64").toString();

          const details = JSON.parse(decode);
          localStorage.add("userType", response.type);

          if (props.requireAccessCode === "true")
            router.push({
              name: "Access Code",
            });
          else router.push(`/sign/details?contractId=${details.contractId}`);
        } else {
          if (props.afterLoginData) {
            const afterLoginData = JSON.parse(props.afterLoginData);
            router.push({
              name: afterLoginData.name,
              query: afterLoginData.query,
              params: afterLoginData.params,
            });
          } else {
            router.push({ name: "Dashboard" });
          }
        }
      }
    };

    return {
      form,
      verifyCode,
    };
  },

  methods: {
    cancelLogin() {
      this.$router.push({ name: "Login" });
    },
  },
});
