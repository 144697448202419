import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5f6d7081"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "two-factor-page" }
const _hoisted_2 = { class: "white-container" }
const _hoisted_3 = { class: "tagline" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "row-button w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_validation_text_field = _resolveComponent("validation-text-field")!
  const _component_text_button = _resolveComponent("text-button")!
  const _component_flat_button = _resolveComponent("flat-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("login.twoFactorTagline")), 1),
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("login.twoFactorTitle")), 1)
      ]),
      _createElementVNode("p", {
        innerHTML: _ctx.$t('login.twoFactorMessage'),
        class: "message mt-5"
      }, null, 8, _hoisted_5),
      _createVNode(_component_validation_text_field, {
        class: "code-text-field mt-5",
        placeholder: _ctx.$t('login.twoFactorHint'),
        "keep-error-space": false,
        rules: "required",
        message: {
          error_required: _ctx.$t('errors.required'),
        },
        name: "authenticationCode"
      }, null, 8, ["placeholder", "message"]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_text_button, {
          class: "cancel-btn",
          text: _ctx.$t('login.twoFactorCancel'),
          onOnClick: _ctx.cancelLogin
        }, null, 8, ["text", "onOnClick"]),
        _createVNode(_component_flat_button, {
          text: _ctx.$t('login.twoFactorConfirm'),
          enabled: _ctx.form.values.authenticationCode.length > 5,
          class: "btn-confirm",
          onOnClick: _ctx.verifyCode
        }, null, 8, ["text", "enabled", "onOnClick"])
      ])
    ])
  ]))
}